<template>
    <div>
        <a-card v-if="student">
            <div class="w-full">
                <div class="flex justify-between">
                    <h3 class="font-bold text-gray-800 mb-4">Datos del estudiante</h3>
                    <div>
                        <h2 v-if="student.is_retired" class="text-red-400">Retirado</h2>
                        <h2 v-if="!getEnabled" class="text-red-400">Desactivado</h2>
                    </div>
                </div>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Cédula de identidad</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.id_document }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Nombres</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.first_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Apellidos</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.last_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Correo electrónico</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.email }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Correo electrónico de institución</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.institution_email || '-- Sin correo --' }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Género</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.gender === 'male' ? 'Masculino' : 'Femenino' }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Turno</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ getSheduleShift(student.schedule_shift) }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Teléfono</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.phone || '-- Sin teléfono --' }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Teléfono celular</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.mobile_phone }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Lugar de nacimiento</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate" v-tooltip="student.birth_place">
                                {{ student.birth_place }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Fecha de nacimiento</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.birth_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Dirección</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate" v-tooltip="student.address">
                                {{ student.address }}
                            </div>
                        </div>
                        <!-- <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Fax</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.fax }}
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="w-full mt-8">
                <h3 class="font-bold text-gray-800 mb-4">Redes sociales</h3>
                <div class="flex flex-wrap -mx-2">
                    <div class="w-1/3 px-2">
                      <div class="flex flex-wrap items-center">
                        <div class="flex flex-wrap items-center bg-gradient-to-br from-blue-600 to-blue-700 p-2 rounded-md">
                          <a-icon name="facebook" class="mr-2 w-5 h-5 text-white" />
                          <span class="text-white">{{ student.social_networks.facebook || '-- Sin cuenta --' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="flex flex-wrap items-center">
                        <div class="bg-gradient-to-br from-yellow-500 to-purple-600 rounded-md p-2 space-x-2 flex items-center justify-center">
                          <a-icon name="instagram" class="w-6 h-6 text-white inline-block" />
                          <span class="text-white">{{ student.social_networks.instagram || '-- Sin cuenta --' }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="flex flex-wrap items-center">
                        <div class="bg-blue-400 rounded-md p-2 space-x-2 flex items-center justify-center">
                            <a-icon name="twitter" class="mr-2 w-6 h-6 text-white" />
                            <span class="text-white">{{ student.social_networks.twitter || '-- Sin cuenta --' }}</span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-8">
                <h3 class="font-bold text-gray-800 mb-4">Estudios realizados</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Educación</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.educational_background }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Título obtenido</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.educational_title }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Actividad actual</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.educational_current_activity }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Nombre del instituto</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.educational_institution_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Fecha de graduación</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.educational_graduation_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Especialidad</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.specialization_id }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-8" v-if="user.isAdmin">
                <h3 class="font-bold text-gray-800 mb-4">Datos del representante</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Nombre del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.representative_name }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Email del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.representative_email }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Profesión del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.representative_profession }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Dirección del representante</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.representative_address }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full mt-4" v-if="false">
                <h3 class="font-bold text-gray-800 mb-4">Datos del pago</h3>
                <div class="w-full flex flex-wrap -mx-2">
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Fecha de pago</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.payment_date }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Total pagado</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.payment_amount }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Tipo de pago</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.payment_type }}
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <b style="color: #4D4F5C">Número de recibo</b>
                            </div>
                            <div class="w-full md:w-1/1 lg:w-1/2 truncate">
                                {{ student.payment_receipt_number }}
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </a-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: mapState({
        user: ({ session }) => session.user,
        student: ({ students }) => students.current,
        getEnabled() {
          return this.student?.user?.enabled
        }
    }),
    methods: {
        getSheduleShift(shift) {
           if (shift === 'morning') return 'Mañana';
           if (shift === 'afternoon') return 'Tarde';
           return 'Mixto'
       },
    },
}
</script>